import Scanner from '@/components/Scanner/Scanner.vue'

export default {
  components: {
    Scanner
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Settings',
          to: { name: 'settings' },
          exact: true
        },
        {
          text: 'Scanner',
          disabled: true
        }
      ],
      readerTypes: [
        'code_128_reader',
        'ean_reader',
        'ean_8_reader',
        'code_39_reader',
        'code_39_vin_reader',
        'codabar_reader',
        'upc_reader',
        'upc_e_reader',
        'i2of5_reader',
        '2of5_reader',
        'code_93_reader'
      ],
      patchSizes: ['x-small', 'small', 'medium', 'large', 'x-large'],
      resolutions: [
        {
          width: 2560,
          height: 1440
        },
        {
          width: 1920,
          height: 1080
        },
        {
          width: 1600,
          height: 1200
        },
        {
          width: 1280,
          height: 720
        },
        {
          width: 800,
          height: 600
        },
        {
          width: 640,
          height: 480
        },
        {
          width: 640,
          height: 360
        }
      ],
      frequencies: [
        {
          text: '0.1s',
          value: 10
        },
        {
          text: '0.2s',
          value: 5
        },
        {
          text: '0.5s',
          value: 2
        },
        {
          text: '1s',
          value: 1
        }
      ],
      workers: [1, 2, 4, 8],
      videoInputDevices: [],
      showScanner: false,
      scannerResult: null
    }
  },
  computed: {
    autoSelectScannerResult: {
      get() {
        return this.$store.state.settings.autoSelectScannerResult
      },
      set(v) {
        this.$store.commit('settings/setAutoSelectScannerResult', v)
      }
    },
    selectedReaderTypes: {
      get() {
        return this.$store.state.settings.selectedReaderTypes
      },
      set(v) {
        this.$store.commit('settings/setSelectedReaderTypes', v)
      }
    },
    locator: {
      get() {
        return this.$store.state.settings.locator
      },
      set(v) {
        this.$store.commit('settings/setLocator', v)
      }
    },
    selectedResolution: {
      get() {
        let resolution = this.resolutions.find(r => r.height === 720)
        const storeResolution = _.get(this, '$store.state.settings.selectedResolution')
        if (!_.isEmpty(storeResolution)) {
          resolution = this.resolutions.find(r => r.height === storeResolution.height && r.width === storeResolution.width)
        }
        return resolution
      },
      set(v) {
        this.$store.commit('settings/setSelectedResolution', v)
      }
    },
    frequency: {
      get() {
        return this.$store.state.settings.frequency
      },
      set(v) {
        this.$store.commit('settings/setFrequency', v)
      }
    },
    numOfWorkers: {
      get() {
        return this.$store.state.settings.numOfWorkers
      },
      set(v) {
        this.$store.commit('settings/setNumOfWorkers', v)
      }
    },
    locate: {
      get() {
        return this.$store.state.settings.locate
      },
      set(v) {
        this.$store.commit('settings/setLocate', v)
      }
    },
    selectedVideoInputDevice: {
      get() {
        return this.$store.state.settings.selectedVideoInputDevice
      },
      set(v) {
        this.$store.commit('settings/setSelectedVideoInputDevice', v)
      }
    }
  },
  methods: {
    async init() {
      const devices = await navigator.mediaDevices.enumerateDevices()
      this.videoInputDevices = devices.filter(device => device.kind === 'videoinput')
    }
  },
  async created() {
    this.init()
  }
}
